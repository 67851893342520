import React, { Component } from 'react';
import {Button} from 'antd';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import MainLayout from './components/MainLayout';
import LoginPage from './pages/LoginPage';

import 'antd/dist/antd.css';

// GraphQL provider for API access
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/client';
import { useCookies } from 'react-cookie';
import { CookiesProvider } from 'react-cookie';
import UniversalCookies from 'universal-cookie';


var graphqlClientUri = 'https://api.autonom.ca';
// var graphqlClientUri = 'http://localhost:4000';

const client = new ApolloClient({
	uri: graphqlClientUri,
	request: (operation) => {
		// Add the user_jwt token for api authentication and authorizations

		// Get token from cookies
		const cookies = new UniversalCookies();
		// console.log("all cookies: ",cookies.getAll());
		const jwt_token = cookies.get('user_jwt');
		// console.log("jwt_token: ",jwt_token);

		// Add to API requests HTTP header "authorization"
		operation.setContext({
			headers: {
				Authorization: jwt_token ? `Bearer ${jwt_token}` : ''
			}
		})
	}
});

function App(props) {

	const [cookies, setCookie, removeCookie] = useCookies(['usr']);
	console.log(cookies);
	const loggedIn = cookies.usr != undefined;
	console.log("loggedIn:", loggedIn);

	console.log(cookies.usr, typeof(cookies.usr));
	const username = loggedIn ? cookies.usr.match(/^s:(.*)\..*$/)[1] : null; // Extract signed value
	
	// console.log("username:", username);
	return (
		<ApolloProvider client={client}>
		<Router>
			<Switch>
				<Route path="/login">
					{ loggedIn ? <Redirect to="/dashboard" /> : <LoginPage /> }
				</Route>
				<Route exact path="/">
					<Redirect to="/dashboard" />
				</Route>
				<Route>
					{ loggedIn ? null : <Redirect to="/login" /> }
				</Route>
			</Switch>

		{ loggedIn ? <MainLayout/> : null }
		</Router>
		</ApolloProvider>
	);
}



export default App;