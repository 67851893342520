
import React from 'react';
import {Icon, Popover, Typography, Row, Col, Modal, Button} from 'antd';
const {Text} = Typography;



export default function Marker(props) {

	const {onShowDetails, device:{device_id, latest}} = props;
	

	// <Text>Available Runtime: </Text><Text strong style={{color:'red'}}>1.23h</Text>  <Icon type="warning" theme="twoTone" twoToneColor="#f00" /><br/>
	const popContent = <>
			<Text>ID: </Text><Text strong>{device_id}</Text><br/>
			<Text>Current State: </Text><Text strong>{latest && latest.state}</Text><br/>
			<Text>Battery Voltage: </Text><Text strong>{latest && latest.pack_V} V</Text><br/>
			<a onClick={() => {onShowDetails(device_id)} }>Details</a>
		</>

	return (
		<div>
		<Popover content={popContent}
			title={null}
			trigger="hover">
		<Icon type='environment'
			theme="filled"
			style={{fontSize:20, color:props.color}}
			/>
		</Popover>
		</div>
		);
}
