import React, {useState} from 'react';
import {Form, Input, Spin, Icon, Col, List, Button} from 'antd';
import removeFromArray from 'lodash/remove';


function RecipientSelectInput({onValidate}) {

	const [val, setVal] = useState(null);
	const [icon, setIcon] = useState(null);
	const [valid, setValid] = useState(null);

	const onChange = (value) => {
		// Handle search and fetches
		if(!value)
			setIcon(null);
		else
			setIcon('mail');

		if(!value)
			setValid(null)
		else if(value.length < 2)
			setValid('warning');
		else
			setValid('success');
	}

	const onPressEnter = (event) => {
		event.preventDefault(); // To avoid submitting the form
		// console.log("onPressEnter()", event.target.value);

		// Validation (phone vs email vs)
		let rec = {type:"email", value:event.target.value};

		onValidate(rec);
		setVal(null);
		setIcon(null);
		setValid(null);
	}

	return <Form.Item validateStatus={valid} style={{marginBottom:0}}>
				<Input
				placeholder='Enter email address'
				onPressEnter={onPressEnter}
				onChange={(e) => {onChange(e.target.value); setVal(e.target.value)}}
				value={val}
				suffix={ <Icon type={icon} theme="twoTone" twoToneColor="orange" /> }
				/>
			</Form.Item>
}




export default function RecipientSelector(props) {
	const {onChange} = props; // 'onChange' is added by form.getFieldDecorator called on this component
	const [recipients, addRecipient, removeRecipient] = useRecipientsList(props.recipients ? props.recipients : [], onChange);

	let rec = props.recipients ? props.recipients : recipients;

	return <>
			<RecipientSelectInput onValidate={addRecipient} />
			<List
				dataSource={rec.map(x => x.value)}
				bordered={false}
				size="small"
				renderItem={(r) => 
					<List.Item actions={[<Icon type='cross' onClick={() => removeRecipient(r)} />]} >
						<Icon type='mail'/>  {r}
					</List.Item>}
			/>
		</>
}



function useRecipientsList(default_val, onRecipientsChange=null) {
	const [recipients, setRecipients] = useState(default_val);

	const _setRecipients = (recipients) => {
		setRecipients(recipients);
		if(onRecipientsChange)
			onRecipientsChange(recipients);
	}

	const addRecipient = (recipient) => {
		// console.log("adding recipient", recipient);
		_setRecipients(recipients.concat(recipient));
	}

	const removeRecipient = (recipient) => {
		// console.log("removing recipient", recipient);
		_setRecipients(removeFromArray(recipients, (a) => a.value !== recipient));
	}

	return [recipients, addRecipient, removeRecipient];
}