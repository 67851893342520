import React from 'react';
import {Typography, Tooltip} from 'antd';

const {Text} = Typography;



// Convert a epoch timestamp to a user friendly string
function timestampToString(timestamp, format='relative') {

	if(!timestamp) // Null / Invalid
		return "-";

	if(format === 'date-time') {
		let options = {
			year: 'numeric', month: 'numeric', day: 'numeric',
			hour: 'numeric', minute: 'numeric', second: 'numeric',
			timeZoneName: 'short',
			hour12: false,
		};
		
		// Friendly date time format
		return Intl.DateTimeFormat('en-CA', options).format(timestamp*1000);
	}
	else if(format === 'ticks') {
		const now = new Date();
		const elapsed_sec = Math.floor(now.getTime() / 1000) - timestamp;
		let text = '';

		if(elapsed_sec >= 86500 && elapsed_sec < 2*86500) // 1 day
			text = "1 day";
		else if(elapsed_sec >= 2*86500)
			text = String((elapsed_sec/86500).toPrecision(2)) + " days";
		else if(elapsed_sec >= 3600) // 1 hour
			text = String((elapsed_sec/3600).toPrecision(2)) + " h";
		else if(elapsed_sec >= 60) // 1 min
			text = String(Math.floor(elapsed_sec/60) + " min");
		else
			text = String(Math.floor(elapsed_sec/60) + " sec");

		return text;
	}
	else {
		const now = new Date();
		const elapsed_sec = Math.floor(now.getTime() / 1000) - timestamp;
		let text = '';
			
		if(elapsed_sec >= 86500 && elapsed_sec < 2*86500) // 1 day
			text = "1 day";
		else if(elapsed_sec >= 2*86500)
			text = String(Math.floor(elapsed_sec/86500)) + " days";
		else if(elapsed_sec >= 3600) // 1 hour
			text = String(Math.floor(elapsed_sec/3600)) + " h";
		else if(elapsed_sec >= 60) // 1 min
			text = String(Math.floor(elapsed_sec/60) + " min");
		else
			text = String(Math.floor(elapsed_sec/60) + " sec");

		return text;
	}
}


function DateTimeLabel(props) {

	const {timestamp, format} = props;

	let text = timestampToString(timestamp, format);

	let content = <Text strong={props.strong}>{text}</Text>;
	
	// Add tooltip
	if(props.tooltip) {
		content = <Tooltip title={timestampToString(timestamp, 'date-time')} placement='bottom'>
		{content}
		</Tooltip>;
	}

	return content;
}

export {timestampToString};
export default DateTimeLabel;


// # Format a timestamp in different ways
// def format_timestamp(value, format='diff_str', totalSeconds=False):
// 	if not value: # Support for None values
// 		return '-'

// 	if format == 'diff_str': # Number of seconds/minutes/days up to now (ex : 23 min, 4 h, 20 sec)
// 		elapsed_sec = round(time.time()) - value
// 		if 2 * 86400 > elapsed_sec >= 86400: #  1 day
// 			return "1 day"
// 		elif elapsed_sec >= 2 * 86400: #  2 days or more
// 			return str(round(elapsed_sec / 86400, 2)) + " days"
// 		elif elapsed_sec >= 3600: # 1 hour
// 			return str(round(elapsed_sec / 3600, 2)) + " h"
// 		elif elapsed_sec >= 60: # 1 min
// 			return str(floor(elapsed_sec / 60)) + " min"
// 		else:
// 			return str(elapsed_sec) + " sec"

// 	elif format == 'diff_sec_str': # Number of seconds up to now (ex : 237 sec)
// 		elapsed_sec = value - round(time.time())
// 		return str(elapsed_sec) + " sec"

// 	elif format == 'diff': # Number of seconds up to now as an int
// 		return round(time.time()) - value # Return as an int

// 	elif format == 'date_time': # Friendly date and time format (ex : Fri Jan 12 14:13:22 2018)
// 		return time.ctime(value)

// 	elif format == 'date':
// 		return time.strftime("%Y-%b-%d").lower()

// 	return value
