import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Icon, Input, Button, Checkbox, Typography, Row } from 'antd';
import axios from 'axios';


const {Title, Text} = Typography;

function LoginForm(props) {
	let history = useHistory();
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleSubmit = e => {
		e.preventDefault();
		
		props.form.validateFields((err, values) => {
			if (!err) {
				// console.log('Received values of form: ', values);
				axios({
					method: 'post',
					url: '/login',
					data: {
						username: values.username,
						password: values.password,
						// remember: values.remember
					},
					withCredentials: true // (send cookies)
				})
				.then( rep => {
					const {username, message} = rep.data;
					console.log("username, message = ", username, message);

					if(username) {
						// We need to force rerendering of the App
						window.location.reload(); 	// TODO: realoading works, but maybe there would be a better way
													// Use Redux !
					} else if(message) {
						setMessage(`Login failed: ${message}`);
					} else {
						setMessage('Login failed: Unknown error');
					}

				})
				.catch( err => {
					console.error("Server error:", err);
					setMessage("Error communicating with server. Report to administrator (raphael@autonom.ca)");

					//history.push("https://www.autonom.ca");
					// history.push("https://www.autonom.ca");
				})
				.finally( () => {
					setLoading(false);
				});
				
				setLoading(true);
			}
		});
	};

	const { getFieldDecorator } = props.form;

	return (
		<Row>
			<Title level={3} style={{textAlign:'center', marginTop:50}}>Welcom on Autonom Portal</Title>
			<Text className="form-message">{message}</Text>
			<Form onSubmit={handleSubmit} className="login-form">
				<Form.Item>
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your username!' }],
					})(
						<Input
							prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
							placeholder="Username"
						/>,
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator('password', {
						rules: [{ required: true, message: 'Please input your Password!' }],
					})(
						<Input
							prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
							type="password"
							placeholder="Password"
						/>,
					)}
				</Form.Item>
				<Form.Item>
					{/*
					{getFieldDecorator('remember', {
						valuePropName: 'checked',
						initialValue: true,
					})(<Checkbox>Remember me</Checkbox>)}
					<a className="login-form-forgot" href="">
						Forgot password
					</a>
					Or <a href="">register now!</a>
					*/}
					<Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
						Log in
					</Button>
				</Form.Item>
				<style jsx>{`
					.login-form {
						max-width: 300px;
						margin: 50px auto;
					}
					.login-form .login-form-forgot {
						float: right;
					}
					.login-form-button {
						width: 100%;
					}
					.form-message {
						display: block;
						text-align: center;
						font-size: 16px;
						color: red;
					}
				`}</style>
			</Form>
		</Row>

	);
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

export default WrappedNormalLoginForm;