import React, {useState} from 'react';

import { Button, Table, Divider, Tag, Card, Layout, Row, Col, Icon, Tooltip, Typography } from 'antd';

import { useQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import { StateToString } from './utils';

import DateTimeLabel from '../components/DateTimeLabel';
import LocationLabel from '../components/LocationLabel';
import AlertConfigDrawer from '../components/AlertConfigDrawer';

const {Text} = Typography;

const QUERY_DEVICE_LIST = gql`
	{
		device_list {
			device_id
			tags
			lastCommunication
			creation_date
			alerts
			location {
				lat
				lon
				address_detail {
					country
					country_code
					state
					city
					county
					postal_code
					road
				}
			}
			latest {
				timestamp
				state
			}
		}
	}
`;





const columns = [
	{
		title: 'Device ID',
		dataIndex: 'device_id',
		key: 'device_id',
		sortDirections: ['ascend', 'descend'],
		sorter: (a,b) => {return a.device_id < b.device_id ? -1:1},
	},
	{
		title: 'Last Communication',
		dataIndex: 'lastCommunication',
		sortDirections: ['descend', 'ascend'],
		sorter: (a,b) => {return a.lastCommunication < b.lastCommunication ? -1:1},
		render: ts => <DateTimeLabel timestamp={ts} />
	},
	{
		title: 'State',
		dataIndex: 'latest',
		sortDirections: ['descend', 'ascend'],
		sorter: (a,b) => {
			if(a.latest && b.latest)
				return a.latest.state < b.latest.state ? -1:1
			else if(a.latest && !b.latest)
				return -1;
			else if(!a.latest && b.latest)
				return 1;
			else
				return 0;
		},
		render: latest => {
			if(!latest || !latest.timestamp)
				return null;

			if(StateToString(latest.state) != "Depleted" && Date.now()/1000 - latest.timestamp > 60*5)
				return 'Disconnected';
			else
				return StateToString(latest.state);
		}
	},
	// {
	// 	title: 'Comissioning',
	// 	dataIndex: 'creation_date',
	// 	sortDirections: ['descend', 'ascend'],
	// 	sorter: (a,b) => {return a.creation_date < b.creation_date ? -1:1},
	// 	render: ts => <DateTimeLabel timestamp={ts} tooltip />
	// },
	// {
	// 	title: 'Remaining runtime',
	// 	// dataIndex: 'creation_date',
	// 	sortDirections: ['descend', 'ascend'],
	// 	// sorter: (a,b) => {return a.creation_date < b.creation_date ? -1:1},
	// 	// render: ts => <DateTimeLabel timestamp={ts} tooltip />
	// },
	// {
	// 	title: 'Serial number',
	// 	dataIndex: 'serial_no',
	// 	key: 'serial_no',
	// 	sortDirections: ['ascend', 'descend'],
	// 	sorter: (a,b) => {return a.serial_no < b.serial_no ? -1:1},
	// },
	{
		title: 'Location',
		dataIndex: 'location',
		key: 'location',
		render: loc => <LocationLabel location={loc}/> ,
	},
	// {
	// 	title: 'Warranty',
	// 	key: 'creation_date',
	// 	render: warranty => <Tooltip placement='right' title='Valid'><Icon style={{fontSize:20}} type="check-circle" theme="twoTone" twoToneColor="#52c41a"/></Tooltip>
	// },
	{
		title: 'Tags',
		key: 'tags',
		dataIndex: 'tags',
		render: tags => (
			<span>
				{tags.map(tag => {
					let color = tag.length > 5 ? 'geekblue' : 'green';
					if (tag === 'loser') {
						color = 'volcano';
					}
					return (
						<Tag color={color} key={tag}>
							{tag}
						</Tag>
					);
				})}
			</span>
		),
	},
];



function DeviceTable(props) {

	const {loading, error, data, refetch} = useQuery(QUERY_DEVICE_LIST, {errorPolicy:'all', fetchPolicy: "no-cache", onCompleted:() => console.log("completed"), onError:() => console.log("error") });

	console.log("isLoading", loading);
	const [selectedDevices, setSelectedDevices] = useState([]);
	const [alertDrawerVisible, setAlertDrawerVisible] = useState(false);

	const handleAddAlert = () => {
		// console.log(selectedDevices);
		setAlertDrawerVisible(true);
		// TODO : add alert for selected devices
	};

	const onRefresh = () => {
		refetch();
	}

	return (
		<Col>
			<Row style={{marginBottom:16}}>
				<Button style={{marginRight:5}} type='primary' onClick={handleAddAlert} disabled={!selectedDevices.length}>Add Alert</Button>
				<Button style={{marginRight:5}} type='secondary' icon="sync" onClick={onRefresh} disabled={loading}>Refresh</Button>
			</Row>
			<Row>
				<Table columns={columns}
					dataSource={data ? data.device_list: []}
					loading={loading}
					rowKey='device_id'
					rowSelection={{
									selectedRowKeys:selectedDevices,
									onChange:setSelectedDevices,
								}}
					style={{background:'#fff'}}
					size='middle'
					onRow={(record, rowIndex) => {
									return {
										onClick: event => {props.onShowDevice && props.onShowDevice(record.device_id)},
									};
								}}
				/>
			</Row>
			<Row></Row>
			<AlertConfigDrawer devices={selectedDevices} visible={alertDrawerVisible} onClose={() => setAlertDrawerVisible(false)} />
		</Col>
	);

}

export default DeviceTable;
