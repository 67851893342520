import React from 'react';
import {List, Button, Icon, Typography, Row, Col, message} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';

const { Title, Paragraph, Text } = Typography;

const QUERY_ALL_ALERTS = gql`
query myQuery {
	getAlertList {
		alert_id
		devices
		recipients {
			type
			value
		}
	}
}`;


const MUTATION_REMOVE_ALERT = gql`
mutation myMutation($alert_id: String!) {
  removeAlert(alert_id: $alert_id)
}
`;


function ListItem({item, onDeleteAlert}) {

	let alertMedia;

	alertMedia = (
		<>
			<Icon type="mail" theme="twoTone" style={{fontSize:'30px', textAlign:'center'}} /><br/>
			<Text>Email alert</Text>
		</>
		);


	let alertType;

	if(item.type === "outage") {
		alertType = <>
			<Row><Text strong>Alert when</Text></Row>
						<Row><Icon type="api" theme="twoTone" twoToneColor="orange" style={{fontSize:'30px'}}/></Row>
						<Row><Text>Power Outage</Text></Row>
		</> ;
	} else if(item.type === "ac_recovery") {
		alertType = <>
			<Row><Text strong>Alert when</Text></Row>
						<Row><Icon type="bulb" theme="twoTone" style={{fontSize:'30px'}}/></Row>
						<Row><Text>AC Recovery</Text></Row>
		</> ;
	} else if(item.type === "down") {
		alertType = <>
			<Row><Text strong>Alert when</Text></Row>
						<Row><Icon type="warning" theme="twoTone" twoToneColor="red" style={{fontSize:'30px'}}/></Row>
						<Row><Text>Down</Text></Row>
		</> ;
	} else  {
		alertType = <>
			<Row><Text strong>Alert when</Text></Row>
			<Row><Icon type="warning" theme="twoTone" twoToneColor="red" style={{fontSize:'30px'}}/></Row>
			<Row><Text>Down</Text></Row>
		</> ;
	}

	return (
		<Row
			style={{background:'#fff', marginBottom:10, padding:10}}
			>
			<Row type="flex" justify='space-between' align="middle" gutter={10}>
				<Col span={1} style={{textAlign:'center'}}>
					<Icon type='bell' theme='twoTone' style={{fontSize:30}} twoToneColor='orange'/>
				</Col>
				<Col span={3}>
					<Row><Text strong>{item.recipients.length} Recipient{item.recipients.length > 1 ? 's':null}</Text></Row>
					{item.recipients.map(x => <Row><Icon type="mail" theme="twoTone"/> {x.value}</Row>)}
				</Col>
				<Col span={4}>
					<Row><Title level={2} style={{margin:0}}>{item.devices.length}</Title><Text>Unit{item.devices.length > 1 ? 's':null} monitored</Text></Row>
				</Col>
				<Col span={3}>
					<Row><Text strong>Units Location</Text></Row>
					<Row><Text><Icon type="environment" /> Bangalore</Text></Row>
				</Col>
				<Col span={5} style={{textAlign:'center'}}>
					{alertType}
				</Col>
				<Col span={2} style={{textAlign:'center'}}>
					{/*<Button icon="edit" type="primary" ghost style={{marginRight:10}}/>*/}
					<Button icon="delete" type="danger" ghost onClick={() => onDeleteAlert(item.alert_id)}/>
				</Col>
				</Row>
		</Row>
		);
}


export default function AlertList(props) {

	const {loading, error, data, refetch} = useQuery(QUERY_ALL_ALERTS);
	const [removeAlert, mut] = useMutation(MUTATION_REMOVE_ALERT);
	
	const onRefresh = () => {
		refetch();
	}

	const {getAlertList:alertList = []} = data || []; // Extract result in alertList

	const listHeader = () => {
		return(
			<Row type="flex" justify="start" align="middle" gutter={10}>
				{/*
				<Col><Button icon="plus" type="primary" onClick={props.onNewClick} >New Alert</Button></Col>
				*/}
				<Col><Text strong>{alertList.length} Alerts configured</Text></Col>
				<Col><Button onClick={onRefresh} icon="sync" disabled={loading}>Refresh</Button></Col>
			</Row>
		);
	}

	const onDeleteAlert = (alert_id) => {
		removeAlert({variables:{alert_id:alert_id}}).then(d => {
			message.info("Alert removed");
			refetch();
		});
	}


	return (
		<List itemLayout="horizontal"
			bordered={false}
			dataSource={alertList}
			renderItem={(item) => <ListItem item={item} onDeleteAlert={onDeleteAlert} />}
			header={listHeader()}
		/>	
	);
}