
import React from 'react';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, Legend, Tooltip, CartesianGrid } from 'recharts';

const data = [	
				{name: '0.5h', units: 30},
				{name: '1h', units: 50}, 
				{name: '2h', units: 28}, 
				{name: '3h', units: 10}, 
				{name: '5h', units: 3}, 
				{name: '6+ hour', units: 1}
			];

class OutageDurationGraph extends React.Component {

	render () {

		return (
			<ResponsiveContainer width="100%" height={200}>
			  <ComposedChart  data={data}>
				<Bar dataKey="units" fill="#237DF7" />
				<XAxis dataKey="name" />
				<Tooltip />
			  </ComposedChart>
			</ResponsiveContainer>
			);
	}
}


export default OutageDurationGraph;