
import React, {useState} from 'react';
import { Spin, Select, Col, Row, Button, Icon, Popover, Dropdown, Menu } from 'antd';
import { ResponsiveContainer, ScatterChart, Scatter, ZAxis, XAxis, YAxis, Legend, Tooltip, CartesianGrid, Dot } from 'recharts';
import { useQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import {timestampToString} from '../components/DateTimeLabel';

const { Option } = Select;

const sample_data = [
	{"timestamp":0,  "pack_mV":4000, "temperature":25.5},
	{"timestamp":2,  "pack_mV":3900, "temperature":26},
	{"timestamp":4,  "pack_mV":3800, "temperature":27},
	{"timestamp":5,  "pack_mV":3600, "temperature":32},
	{"timestamp":7,  "pack_mV":3500, "temperature":null},
	{"timestamp":9,  "pack_mV":3300, "temperature":35},
	{"timestamp":15, "pack_mV":3300, "temperature":34},
];



const QUERY_DEVICE_DATA = gql`
query Device($device_id: String!, $limit: Int = null, $tmin: Int = null, $tmax: Int = null) {
	device(device_id: $device_id) {
		data(limit: $limit, tmin: $tmin, tmax: $tmax) {
			timestamp
			pack_V
			temperature
			state
			pack_A
			output_W
			input_W
			soc_100
			cells {
				id
			}
		}
	}
}
`;

const QUERY_DEVICE_LAST_COMM = gql`
query Device($device_id: String!) {
	device(device_id: $device_id) {
		lastCommunication
	}
}
`;


function renderCustomTimestampTicks({ x, y, payload }) {
	// console.log(x, y, payload);
	// console.log(timestampToString(payload.value, 'ticks'));

	return <text width="342"
							height="30"
							x={x}
							y={y} stroke="none"
							fill="#666"
							class="recharts-text recharts-cartesian-axis-tick-value"
							text-anchor="middle">
								<tspan x={x} dy="0.71em">
									{timestampToString(payload.value, 'ticks')}
								</tspan>
					</text>;
}



let availablePlots = [
	{
		datakey: "pack_A",
		color: "#009A21",
		unit: "A",
		name: "Battery Current",
		domain: [-1.2, 4],
		hide: true
	},
	{
		datakey: "pack_V",
		color: "#FF9000",
		unit: "V",
		name: "Battery Voltage",
		domain: [2.5, 4.2]
	},
	{
		datakey: "temperature",
		color: "#008AFF",
		unit: "°C",
		name: "Battery Temperature",
		domain: [10, 70],
		hide: true
	},
	{
		datakey: "output_W",
		color: "#048DFF",
		unit: "W",
		name: "Output Power",
		domain: [0, 200],
		hide: true
	},
	{
		datakey: "input_W",
		color: "#04c0ff",
		unit: "W",
		name: "Input Power",
		domain: [0, 200],
		hide: true
	},
	{
		datakey: "soc_100",
		color: "#CA86FF",
		unit: "%",
		name: "State of Charge",
		domain: [0, 100],
		hide: true
	}
];

const getPlot = (datakey) => {
	for(let plot of availablePlots)
		if(plot.datakey == datakey)
			return plot;

	return {};
}

export default function DataChart(props) {

	// const [date, setDate] = useState(new Date());
	const [render, reRender] = useState(false);
	const forceRender = () => reRender(!render); // Toggle value to force render

	const addPlot = (datakey) => {
		let plot = getPlot(datakey);
		plot.hide = false;
		forceRender();
	};

	const removePlot = (datakey) => {
		let plot = getPlot(datakey);
		plot.hide = true;
		forceRender();
	};

	console.log("Rerendering", availablePlots);
	// console.log(props.device_id);
	let axisname = props.axisname || null;
	let datakey = props.datakey || null;
	let color = props.color || '#FFA406';

	const now = new Date();
	const epoch = Math.floor(now.getTime()/1000);
	// Skip request if device_id is invalid (happens on first render)
	let skip = false;
	if(!props.device_id || props.device_id === '' || props.data)
		skip = true;

	// const now = new Date();
	// let from_now_24h = Math.floor(now.getTime() / 1000) - 60*60*24;

	// console.log("device_id:", props.device_id, "skipping:", skip);
	const {loading: loading_, error:error_, data:data_} = useQuery(QUERY_DEVICE_LAST_COMM, {variables: { device_id:props.device_id }, skip:skip });

	// console.log(loading_, error_, data_);

	// Manage time axis
	const [timeRange, setTimeRange] = useState({tmax:epoch, tmin:(epoch - 5*3600) - ((epoch - 5*3600) % 60)});

	const updateTimeAxis = (action) => {
		if(!data_)
			return;

		console.log("updateTimeAxis(",action,")")

		if(!action)
			action = 'LAST_5_HOURS'; // Default value

		if(action === 'PREVIOUS') {
			// Load previous time frame with same length
			let length = timeRange.tmax - timeRange.tmin;
			setTimeRange({
				tmin:timeRange.tmin - length,
				tmax:timeRange.tmax - length,
			});
		}
		else if(action === 'NEXT') {
			// Load previous time frame with same length
			let length = timeRange.tmax - timeRange.tmin;
			if(timeRange.tmax + length > epoch)
				length = epoch - timeRange.tmax; // Make sure we don't display the future
			console.log('NEXT', length, timeRange.tmax + length > epoch, timeRange.tmax - timeRange.tmin);

			setTimeRange({
				tmin:timeRange.tmin + length,
				tmax:timeRange.tmax + length,
			});
		}
		else if(action === 'LAST_5_HOURS') {
			let fiveHoursFromNow = (epoch - 5*3600);
			setTimeRange({
				tmin:fiveHoursFromNow - fiveHoursFromNow % 60, // Round to avoid reloading too fast
				tmax:epoch,
			});
		}
		else if(action === '5_HOURS') {
			// 5 Hours from tmax
			setTimeRange({
				tmin:timeRange.tmax - 5*3600,
				tmax:timeRange.tmax
			})
		}
		else if(action === '10_HOURS') {
			// 10 Hours from tmax
			setTimeRange({
				tmin:timeRange.tmax - 10*3600,
				tmax:timeRange.tmax
			})
		}
		else if(action === '24_HOURS') {
			// 24 Hours from tmax
			setTimeRange({
				tmin:timeRange.tmax - 24*3600,
				tmax:timeRange.tmax
			})
		}
		else if(action === '48_HOURS') {
			setTimeRange({
				tmin:timeRange.tmax - 48*3600,
				tmax:timeRange.tmax
			})
		}
		else if(action === 'LAST_DATA') {
			// Show last data
			let length = timeRange.tmax - timeRange.tmin;
			setTimeRange({
				tmin:data_.device.lastCommunication - length,
				tmax:data_.device.lastCommunication,
			})
		}
		// tmax = data_.device.lastCommunication;
		// tmin = data_.device.lastCommunication - 24*3600;
	}

	const menu = <Menu onClick={(e) => updateTimeAxis(e.key)}>
		<Menu.Item key="LAST_5_HOURS">Last 5 hours</Menu.Item>
		<Menu.Item key="LAST_DATA">Last data</Menu.Item>
		<Menu.Item key="5_HOURS">Show 5 hours</Menu.Item>
		<Menu.Item key="10_HOURS">Show 10 hours</Menu.Item>
		<Menu.Item key="24_HOURS">Show 24 hours</Menu.Item>
		<Menu.Item key="48_HOURS">Show 48 hours</Menu.Item>
	</Menu>;

	// let tmax = null;
	// let tmin = null;
	
	if(loading_ || props.data)
		skip = true;
	else if(data_) {
		// tmin = (epoch - 5*3600);
		// tmin = tmin - tmin % 60; // To ensure it does not reload too fast
	}
	
	const {loading, error, data} = useQuery(QUERY_DEVICE_DATA,
		{
			variables:
				{ 	device_id:props.device_id,
					limit:500,
					tmax:timeRange.tmax,
					tmin:timeRange.tmin
				},
			skip:skip,
			errorPolicy: 'all' // Receive data even if there are graphql errors
		});

	// console.log(loading, error, data);

	// if(loading || skip)
	// 	return <Spin style={{width:'100%', height:250}} />;

	if(props.data)
		data = props.data; // Use provided data

	// Prepare the data for plotting
	// console.log(data);
	let plotData = null;
	if(data)
		plotData = data.device.data;

	let packV_plot = getPlot("pack_V");
	console.log(plotData);
	if(plotData && plotData.length > 0 && plotData[0].cells) {
		console.log("packV_plot", packV_plot);
		packV_plot.domain = [2.5* plotData[0].cells.length, 4.5* plotData[0].cells.length];
		console.log(getPlot("pack_V"));
	}
	else
		packV_plot.domain = [2.5, 4.2];

	console.log(getPlot("pack_V").domain || ['auto','auto']);

	let yaxises = availablePlots.map( params => {
			if(params.hide)
				return null;
			else
				return <YAxis
					yAxisId={params.datakey}
					type='number'
					dataKey={params.datakey}
					unit={params.unit}
					stroke={params.color}
					domain={ params.domain || ['auto','auto']}
					allowDataOverflow 
				/>
		});

	let scatters = availablePlots.map( params => {
						if(params.hide)
							return null;
						else
							return <Scatter
								yAxisId={params.datakey}
								dataKey={params.datakey}
								data={plotData}
								name={params.name || params.datakey}
								line
								fill={params.color}
								isAnimationActive={false}
								shape=<Dot r={3}/>
							/>
					});

	let options = availablePlots.map( params => 
			<Option value={params.datakey} disabled={!params.hide}>{params.name || params.datakey}</Option>
		);

	let tickSpacing = (timeRange.tmax - timeRange.tmin) / 5;

	return (
		<Col style={{margin:"10px 40px"}}>
			<Row style={{marginBottom: 10}} type="flex" justify='space-between'>
				<Button.Group>
					<Button size="small" onClick={() => updateTimeAxis('PREVIOUS')}><Icon type="caret-left" /></Button>
					<Dropdown overlay={menu}>
						<Button size="small" ><Icon type="down" /></Button>
					</Dropdown>
					<Button size="small" onClick={() => updateTimeAxis('NEXT')}><Icon type="caret-right" /></Button>
				</Button.Group>
				<Select
					showSearch
					value={undefined}
					style={{ width: 350 }}
					onChange={ (value) => addPlot(value) }
					placeholder="Add a plot"
					optionFilterProp="children"
					filterOption={(input, option) =>
					  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
				>
					{options}
				</Select>
			</Row>
			<Row>
				<ResponsiveContainer width="100%" height={250} style={{margin:20}}>
					<ScatterChart>
						<CartesianGrid strokeDasharray="3 3" />

						{yaxises}
						{scatters}

						<XAxis type='number'
							dataKey="timestamp"
							name="Time"
							tick={renderCustomTimestampTicks}
							domain={[timeRange.tmin || 'dataMin', timeRange.tmax || 'dataMax']}
							ticks={[timeRange.tmax - 5*tickSpacing, timeRange.tmax-4*tickSpacing, timeRange.tmax-3*tickSpacing, timeRange.tmax-2*tickSpacing, timeRange.tmax-1*tickSpacing, timeRange.tmax]}
						/>
						<Tooltip  isAnimationActive={false} />
						<Legend onClick={ ({dataKey}) => removePlot(dataKey) }/>
					</ScatterChart>
				</ResponsiveContainer>
			</Row>
		</Col>
		);
}
