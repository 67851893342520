import React from 'react';
import {Typography, Icon} from 'antd';

const {Text} = Typography;

export default function LocationLabel(props) {

	if(!props.location) {
		return <Text type='secondary'>-</Text>
	}

	if(props.location.address_detail) {
		return <span><Icon type="environment" /> {props.location.address_detail.country}</span>
	}

	if(props.location.address) {
		return <span>-</span>
	}

	return <Text type='secondary'>-</Text>
}
