import React from 'react';

class DashboardCard extends React.Component {
	render() {

		return (
		<div className="container" style={{padding:15}}>
			{this.props.children}

			<style jsx>{`
				.container {
					background: #fff;
					border-radius: 4px;
					border:solid 1px black;
					margin-bottom: 10px;
					margin-right: 10px;
				}
			`}</style>
		</div>
		);
	}
}


export default DashboardCard;