import React from 'react';
import {Avatar, Button, Popover} from 'antd';
import {useCookies} from 'react-cookie';

export default function UserCard(props) {
	

	const [cookies, setCookie, removeCookie] = useCookies(['usr']);
	
	if(!cookies.usr)
		return null;

	const username = cookies.usr.match(/^s:(.*)\..*$/)[1]; // Extract signed value

	const menu = (
		<Button type='link' onClick={() => {
			removeCookie('user_jwt');
			removeCookie('usr');
			window.location.reload(); // TODO: Use redux to rerender ?
			}}>
		Logout
		</Button>
		);

	return (
		<div style={{margin:'10px auto'}}>
			<Popover content={menu} placement='right' title={username}>
				<Avatar size='medium'
					icon='user'
					style={{margin:'auto', backgroundColor:'rgb(0, 52, 255)'}}
					onClick={() => console.log('123')}>
					{username}
				</Avatar>
			</Popover>
		</div>
	);
}
