import React from 'react';
import {Icon, Spin} from 'antd';
import GoogleMapReact from 'google-map-react';
import Marker from './MapMarker';

import { useQuery } from '@apollo/client';
import { gql } from 'apollo-boost';

const QUERY_DEVICE_LIST = gql`
	{
		device_list {
			device_id
			latest {
				timestamp
				state
				pack_V
				temperature
			}
			location {
				lat
				lon
				address_detail {
					country
					country_code
					state
					city
					county
					postal_code
					road
				}
			}
		}
	}
`;


function MarkersRandomData(clat, clng) {

	let markers = [];
	let i;
	for (i = 0; i < 10; i++) {
		let lat = clat + (Math.random() - 0.5) * 0.2;
		let lng = clng + (Math.random() - 0.5) * 0.2;

		// markers.push( () => <Marker lat={lat} lng={lng}/> );
		markers.push( {lat:lat, lng:lng} );
	}

	return markers;
}

export default function Map(props) {

	const {loading, error, data} = useQuery(QUERY_DEVICE_LIST);
	const {device_list = []} = data || {};

	let markers = [];
	for(let device of device_list) {
		if(device.location && device.location.lat && device.location.lon)
			markers.push({lat:device.location.lat, lng:device.location.lon, device:device});
	}

	const params = {
		center: {
			lat: 45.5016889,
			lng: -73.567256
		},
		zoom: 1
	};

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key:'AIzaSyDsr7ISkHqhWFfvXehzKTnQD_y4T4uL0QM' }}
				defaultCenter={params.center}
				defaultZoom={params.zoom}
				options={{
					styles: require('./MainMapStyle.json')
				}}
				onClick={(e) => {console.log(e)}}
			>
			
			{/*MarkersRandomData(props.center.lat, props.center.lng).map((d) => <Marker lat={d.lat} lng={d.lng} color='red' />)*/}
			
			{markers.map( d => <Marker lat={d.lat} lng={d.lng} color='red' onShowDetails={props.onShowDetails} device={d.device} />)}
			</GoogleMapReact>
			}
		</div>
	);
}

