
import React from 'react';
import { Layout, Menu, Breadcrumb, Icon, Row, Col, Typography, Divider, Table, List, Button, Modal } from 'antd';
import MainLayout from '../components/MainLayout';
import MainMap from '../components/MainMap';
import DeviceTable from '../components/DeviceTable';
import DeviceDetailsModal from '../components/DeviceDetails';


const { Header, Content, Footer, Sider } = Layout;
const { Title, Paragraph, Text } = Typography;


class MapPage extends React.Component {
	state = {
		rightSiderCollapsed: true,
		modalVisible: false,
		showedDeviceId: null
	};

	toggleMap = (e) => {
		console.log(e);
		this.setState({rightSiderCollapsed:!this.state.rightSiderCollapsed});
	}

	// Show details of a device
	showDevice = (device_id) => {
		if(device_id && device_id != '')
			this.setState({modalVisible:true, showedDeviceId:device_id});
	}

	render() {
		return (
				<Layout>
					<Content style={{margin:10}}>
						<Row type='flex' justify='end'>
							<Button onClick={this.toggleMap}>{this.state.rightSiderCollapsed ? 'Show':'Hide'} Map</Button>
						</Row>
						<Row>
							<DeviceTable onShowDevice={this.showDevice} />
						</Row>
					</Content>

					<Sider collapsed={this.state.rightSiderCollapsed} theme='light' width={'50%'} collapsedWidth={0} style={{borderLeft: this.state.rightSiderCollapsed ? 'none':'solid' + ' 2px #9AAFFF'}}>
						<MainMap onShowDetails={this.showDevice}/>
					</Sider>

					<DeviceDetailsModal visible={this.state.modalVisible} onCancel={() => {this.setState({modalVisible:false})} } device_id={this.state.showedDeviceId} />

				</Layout>
		)
	}
}

export default MapPage;
