
import React from 'react';
import {Col, Row, Typography} from 'antd';

const {Text} = Typography;


/**
 * We use the CellsInfo component for displaying data containing voltage_V, current_A, type and id fields. This
 * is valid for outputs, inputs, and also cell's data.
 */
export default function CellsInfo(props) {

	if(!props.data)
		return null;

	return (
		<Col>
			<Row type="flex" justify="center"><Text strong underline style={{fontSize: 15, marginBottom: 5}}>{props.title}</Text></Row>
			<Row>
				{
					props.data.map( (cell) => {

						const elements = [];
						if(cell.voltage_V) elements.push(<Text strong>{cell.voltage_V}V</Text>);
						if(cell.current_A) elements.push(<Text strong>{elements.length > 0 ? ', ' : null}{cell.current_A}A</Text>);
						if(cell.type) elements.push(<Text strong> ({cell.type})</Text>);
						if(cell.temperature) elements.push(<Text strong>{cell.temperature}°C</Text>);

						return <Col span={12}><Text color="#111111">{cell.id}: </Text> {elements}</Col>
					})
				}
			</Row>
		</Col>
	);
}