import React from 'react';
import { Layout, Row, Col, Typography, Card } from 'antd';
import DashboardCard from '../components/DashboardCard';
import OutageDurationGraph from '../components/OutageDurationGraph';
import RuntimeDistributionGraph from '../components/RuntimeDistributionGraph';

const { Header, Content, Footer, Sider } = Layout;
const { Title, Paragraph, Text } = Typography;


export default class DashboardPage extends React.Component {

	render() {
		return (<Layout>
					<Header style={{background:'#fff', padding:'0', outline:'none 1px blue'}}>
					<Title style={{padding:'10px 30px', fontSize:'25px'}}>Dashboard</Title>
					</Header>
					<Content style={{ margin: '16px' }}>
						<Row type="flex" justify="flex-start" style={{padding:'10px'}} gutter={10}>
							<Col span={5}>
								<Card title="On Battery" bordered={false}>
									<Title>13</Title>
									Units
								</Card>
							</Col>
							<Col span={5}>
								<Card title="On AC" bordered={false}>
									<Title>52</Title>
									Units
								</Card>
							</Col>
							<Col span={5}>
								<Card title="Down" bordered={false}>
									<Title>5</Title>
									Units
								</Card>
							</Col>
							<Col span={5}>
								<Card title="Unknown" bordered={false}>
									<Title>1</Title>
									Units
								</Card>
							</Col>
							<Col span={4}>
								<Card title="Stolen" bordered={false}>
									<Title>0</Title>
									Units
								</Card>
							</Col>
						</Row>
						<Row type="flex" justify="flex-start" style={{padding:'10px'}} gutter={10}>
							<Col span={8}>
								<Card title="Outage duration distribution" bordered={false}>
									<OutageDurationGraph/>
								</Card>
							</Col>
							<Col span={8}>
								<Card title="Available runtime distribution" bordered={false}>
									<RuntimeDistributionGraph/>
								</Card>
							</Col>
						</Row>
					</Content>
				</Layout>);
	}
}



/*
KPIs
-> Nombre de sites "on battery"
-> Nombre de sites "down"
-> Nombre de sites "on AC"
-> Nombre de sites "unknown"
-> Nombre de sites "stolen"

-> Distribution de la durée de runtime (heatmap)
-> Distribution de la durée des pannes (heatmap)
	- Power outage 
*/