import React from 'react';
import {Input, Radio, Checkbox, Drawer, Form, Button, Typography, Row, Col, Icon, Select, Spin, message} from 'antd';
import RecipientSelect from '../components/RecipientSelect';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

const {Text, Title} = Typography;
const {Option} = Select;


const MUTATION_ADD_ALERT = gql`
mutation myMutation($alert: AlertInput!) {
	addAlert(alert_obj: $alert) {
		alert_id
		devices
		recipients {
			type
			value
		}
	}
}
`;

const monitorLabel = <>
					<Text>Monitor </Text>
					<Select style={{ width: 300 }}>
					<Option value="all">all units</Option>
					<Option value="zone">specific geographic zone</Option>
					<Option value="selection">specific units</Option>
					</Select>
				</>;

function CustomForm(props) {

	const [addAlert, {loading, data, error}] = useMutation(MUTATION_ADD_ALERT);
	const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form;

	const _onSubmit = e => {
		e.preventDefault();

		const {form} = props;

		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			// console.log('Recipients: ', values.recipients);
			// console.log("Devices", props.devices);

			let alert_obj = {
				devices: props.devices,
				recipients: values.recipients
			};

			// console.log("Submitting:", alert_obj)
			addAlert({variables: {"alert":alert_obj}}).then( d => {
					message.success("New Alert Added !");
					
					if(props.onSuccess)
						props.onSuccess();
			});

			// form.resetFields();
		});

	};


	let monitorContent = null;
	if(props.devices && props.devices.length > 0) {
		monitorContent = <Text>{props.devices.length} Selected Units</Text>;
	}

	return <Form layout="vertical" hideRequiredMark onSubmit={_onSubmit} >
				<Form.Item>
					<Row gutter={16} type='flex' justify='begin'>
						<Col span={24}>
							<Form.Item label="Monitor">
								{monitorContent}
							</Form.Item>
							<Form.Item label="Alert When">
								<Row>
									{ getFieldDecorator('alert_when_power_recovery', {
											initialValue:false,
											valuePropName:'checked'
											})
											(<Checkbox><Icon type="api" theme="twoTone" twoToneColor="blue"/> Power Recovery</Checkbox>)
									}
								</Row>
								<Row>
									{ getFieldDecorator('alert_when_power_outage', {
											initialValue:false,
											valuePropName:'checked'
											})
											(<Checkbox><Icon type="api" theme="twoTone" twoToneColor="orange"/> Power Outage</Checkbox>)
									}
								</Row>
								<Row>
									<Checkbox><Icon type="warning" theme="twoTone" twoToneColor="red"/> Battery Down</Checkbox>
								</Row>
								<Row>
									<Checkbox><Icon type="alert" theme="twoTone" twoToneColor="red"/> Battery Stolen</Checkbox>
								</Row>
								<Row>
									<Checkbox><Icon type="alert" theme="twoTone" twoToneColor="red"/> Battery Need Replacement</Checkbox>
								</Row>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Recipients">
								{ // We only monitor onChange for this component, we don't externally control the value
									getFieldDecorator('recipients', {
										initialValue:[],
										valuePropName:'recipients'
									})(<RecipientSelect />)
								}
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item>
					<Button htmlType="submit" type='primary' loading={loading}>Add</Button>
					<Button disabled={loading} style={{ marginLeft: 8 }} onClick={props.onCancel}>Cancel</Button>
				</Form.Item>
			</Form>
}


export default function AlertConfigDrawer(props) {


	const AlertConfigForm = Form.create({name:'AlertForm'})(CustomForm);

	return (
		<Drawer
			title="Configure alert"
			placement='right'
			closable={false}
			onClose={props.onClose}
			visible={props.visible}
			width={'30%'}
			maskStyle={{backgroundColor:'rgba(0, 0, 0, 0.2)'}}
			maskClosable={false}
			>

			<AlertConfigForm {...props} onSuccess={props.onClose} onCancel={props.onClose} />
			{/*
			<Button onClick={props.onClose} style={{ marginRight: 8 }}>
			Cancel
			</Button>
			<Button onClick={null} htmlType="submit" form="myForm" type="primary">
			Add
			</Button>
			*/}
		</Drawer>
	
	);
}