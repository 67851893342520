import React from 'react';
import { Layout, Row, Col, Typography, Card, List, Checkbox, Button, Icon } from 'antd';
import AlertConfigDrawer from '../components/AlertConfigDrawer';
import AlertList from '../components/AlertList';

const { Header, Content, Footer, Sider } = Layout;
const { Title, Paragraph, Text } = Typography;




const data = [
	{
		units:10,
		media: 'email',
		type: 'outage',
		recipients: {
			users:["Raphael", "Hugues"],
			groups:[]
		}
	},
	{
		units:8,
		media: 'sms',
		type: 'ac_recovery',
		recipients: {
			users:["Raphael", "Hugues"],
			groups:[]
		}
	},
	{
		units:12,
		media: 'email',
		type: 'down',
		recipients: {
			users:["Raphael", "Hugues"],
			groups:[]
		}
	},
	{
		units:1,
		media: 'email',
		type: 'outage',
		recipients: {
			users:["Raphael", "Hugues"],
			groups:[]
		}
	},
];


export default class DashboardPage extends React.Component {

	state = {drawerVisible:false}

	onNewAlert = () => {
		this.setState({drawerVisible:true});
	}

	onDrawerClose = () => {
		this.setState({drawerVisible:false});
	}

	render() {
		return (<Layout>
					<Header style={{background:'#fff', padding:'0', outline:'none 1px blue'}}>
					<Title style={{padding:'10px 30px', fontSize:'25px'}}>Alerts configuration</Title>
					</Header>
					<Content style={{ margin: '16px' }}>
						<AlertList
							onNewClick={this.onNewAlert}
							dataSource={data}
						/>

						<AlertConfigDrawer visible={this.state.drawerVisible} onClose={this.onDrawerClose} />
					</Content>
				</Layout>);
	}
}
