



export function StateToString(state) {
	if(!state)
		return null;
	
	switch(state.toLowerCase()) {
		case "discharging": return "Discharging";
		case "charging": return "Charging";
		case "standby": return "Ready";
		case "shutdown": return "Depleted";
		case "error": return "Error";
		default: return null;
	}
}
