import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import {withRouter} from "react-router";
import { Layout, Menu, Breadcrumb, Icon, Col } from 'antd';

import DashboardPage from '../pages/DashboardPage';
import DeviceListPage from '../pages/DeviceListPage';
import AlertsPage from '../pages/AlertsPage';
import UserCard from '../components/UserCard';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const RouterMenu = withRouter(Menu);


class MainLayout extends React.Component {
	state = {
	collapsed: false,
	selectedPage:'dashboard'
	};

	onCollapse = collapsed => {
	console.log(collapsed);
	this.setState({ collapsed:collapsed });
	};

	handleClick = (e) => {
		console.log(this.props);
		console.log(e);
		this.state["selectedPage"] = e.key; //setState({selectedPage:e.key});
		this.props.history.replace(e.key);
	}

	render() {

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
				<div className="logo" />
				<RouterMenu theme="dark" defaultSelectedKeys={[this.state.selectedPage]} mode="inline" onClick={this.handleClick}>
					<Menu.Item key="/dashboard">
						<Icon type="dashboard" />
						<span>Overview</span>
					</Menu.Item>
					<Menu.Item key="/devices">
						<Icon type="environment" />
						<span>Devices</span>
					</Menu.Item>
					<Menu.Item key="/alerts">
						<Icon type="bell" />
						<span>Alerts</span>
					</Menu.Item>
{/*
					<Menu.Item key="/production">
						<Icon type="pie-chart" />
						<span>Production</span>
					</Menu.Item>

*/}
				</RouterMenu>
				<div style={{margin:'0 auto', textAlign:'center'}}>
					<UserCard />
				</div>
			</Sider>

			
			<Switch>
				<Route path="/devices">
					<DeviceListPage />
				</Route>
				<Route path="/alerts">
					<AlertsPage />
				</Route>
				<Route path='/dashboard'>
					<DashboardPage />
				</Route>
				<Route path='/'>
					<Redirect to="/dashboard" />
				</Route>
			</Switch>
		</Layout>
	);
	}
}

export default withRouter(MainLayout);
