
import React from 'react';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, Legend, Tooltip, CartesianGrid } from 'recharts';

const data = [	
				{name: '0.5h', units: 5},
				{name: '1h', units: 10},
				{name: '1.5h', units: 12},
				{name: '2h', units: 28},
				{name: '2.5h', units: 35},
				{name: '3h', units: 50},
				{name: '3.5h', units: 52},
				{name: '4h', units: 44},
				{name: '4.5h', units: 30},
				{name: '5h', units: 25},
				{name: '5.5h', units: 12},
				{name: '6h', units: 13},
				{name: '6.5h', units: 8},
			];

class RuntimeDistributionGraph extends React.Component {

	render () {

		return (
			<ResponsiveContainer width="100%" height={200}>
			  <ComposedChart  data={data}>
				<Bar dataKey="units" fill="#EA9707" />
				<XAxis dataKey="name"/>
				<Tooltip />
			  </ComposedChart>
			</ResponsiveContainer>
			);
	}
}

export default RuntimeDistributionGraph;