import React, { useState } from 'react';
import { Modal, Spin, Typography, Statistic, Row, Col, Button, Icon, Tooltip, Switch } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';

import DateTimeLabel from './DateTimeLabel';
import LocationLabel from './LocationLabel';
import DataChart from './DataChart';
import CellsInfo from './CellsInfo';
import { get, has } from 'lodash';

import { StateToString } from './utils';

const { Text, Header } = Typography;

const QUERY_DEVICE_DETAILS = gql`
	query Device($device_id: String!) {
		device(device_id: $device_id) {
			creation_date
			lastCommunication
			device_id
			serial_no
			hw_model
			note
			tags
			hw_model
			hw_rev
			access_groups
			access_users
			remaining_runtime_s
			location {
				lat
				lon
				address_detail {
					country
					country_code
					state
					city
					county
					postal_code
					road
				}
			}
			data(limit:1) {
				timestamp
				pack_V
				temperature
				state
				pack_A
				pack_W
				output_W
				input_W
				outputs {
					id
					voltage_V
					current_A
					type
				}
				inputs {
					id
					voltage_V
					current_A
					type
				}
				soc_100
				cells {
					id
					voltage_V
				}
				temperatures {
					id
					temperature
				}
			}
			Flags {
				EnableOutputs
			}
		}
	}
`;

const MUTATION_SET_OUTPUTS_ENABLE = gql`
mutation myMutation($device_id: String!, $flags: DeviceFlagsInput!) {
setDeviceFlags(device_id: $device_id, flags: $flags) {
		EnableOutputs
	}
}
`

function NotificationLabel({notifications}) {

	notifications = [1,2];

	if(!notifications)
		return null;

	return <Tooltip title="Configured notifications" placement='right'><Text style={{fontSize:20}}>{notifications.length} <Icon type="bell" theme="twoTone" twoToneColor='orange' /></Text></Tooltip>;
}


function SmartStats(props) {

	if(!props.value && props.value !== 0)
		return null;

	return <Col span={3}><Statistic {...props} /></Col>;
}


function DeviceDetails(props) {

	// Skip request if device_id is invalid (happens on first render)
	let skip = false;
	if(!props.device_id || props.device_id === '')
		skip = true;

	// console.log("- device_id:", props.device_id, "skipping:", skip);
	const {loading, error, data, refetch} = useQuery(QUERY_DEVICE_DETAILS, {variables: { device_id:props.device_id }, skip:skip, pollInterval:10*1000, errorPolicy:'all'});
	const [setDeviceFlags, deviceFlagsMutation] = useMutation(MUTATION_SET_OUTPUTS_ENABLE);

	// console.log('-- ', loading, data, error);
	const { device = null } = data || {};

	let deviceIsOffline = false;
	if(StateToString(get(device, "data[0].state")) !== 'Depleted' && has(device, "lastCommunication") && Date.now()/1000 - device.lastCommunication > 3600*1) {
		deviceIsOffline = true;
	}

	const [outputsEnabled, setOutputsEnabled] = useState(device?.Flags?.EnableOutputs ?? true);

	// Don't assume we have any data

	const toggleOutputsEnabled = () => {
		setDeviceFlags({
			variables: {
				device_id: props.device_id,
				flags: {
					EnableOutputs: !outputsEnabled
				}
		}})
		.then( d => {
			console.log('value updated to', d.data.setDeviceFlags.EnableOutputs)
			setOutputsEnabled(d.data.setDeviceFlags.EnableOutputs)
		})
	};

	// Don't assume we have any data

	console.log('data', device?.data);
	const lastPoint = device?.data?.[0];

	const inputsDetails = lastPoint?.inputs ? (
	<Col span={6} style={cardStyle}>
		<CellsInfo data={lastPoint.inputs} title="Input details" />
	</Col>) : null;

	const outputsDetails = lastPoint?.outputs ? (
		<Col span={6} style={cardStyle}>
			<CellsInfo data={lastPoint.outputs} title="Outputs details" />
		</Col>) : null;

	const cellsDetails = lastPoint?.cells ? (
		<Col span={6} style={cardStyle}>
			<CellsInfo data={lastPoint.cells} title="Battery details" />
		</Col>) : null;

	const tempDetails = lastPoint?.temperatures ? (
		<Col span={5} style={cardStyle}>
			<CellsInfo data={lastPoint.temperatures} title="Temperature details" />
		</Col>) : null;


	return <Modal
				title="Details"
				visible={props.visible}
				onCancel={props.onCancel}
				centered
				footer={
					<Row type='flex' justify='space-between' align='middle'>
						<Col type='flex'>
							<Text type='secondary'>Updated <DateTimeLabel tooltip strong timestamp={device && device.lastCommunication} /> ago</Text>
							<Button style={{marginLeft:5}} type='secondary' icon="sync" onClick={() => refetch()} disabled={loading}>Refresh</Button>
						</Col>
						<Button type="primary" onClick={props.onCancel}>Close</Button>
					</Row>
				}
				width={"90%"}
				>
					<Spin spinning={loading} delay={200} tip="Loading" size="large">
						<Row type='flex' justify='start'>
							<Col span={8} style={{...cardStyle}}>
								<Text strong>Device ID</Text>: {device && device.device_id}<br/>
								<Text strong>Device Model</Text>: {device && device.hw_model || '(unknown model)'} revision {device && device.hw_rev}<br/>
								<Text strong>Date of comissioning</Text>: <DateTimeLabel timestamp={device && device.creation_date}/> ago <br/>
								<Text strong>Location</Text>: <LocationLabel location={device && device.location} /><br/>
							</Col>
							<Col spa={6} style={cardStyle}>
								<Row type="flex" justify="center"><Text strong underline style={{fontSize: 15}}>Controls</Text></Row>
								<Row style={{margin: 10}}>
									<Text style={{marginRight: 10}}>Output {outputsEnabled ? 'Enabled': 'Disabled'}</Text>
									<Switch checked={outputsEnabled} onChange={() => {toggleOutputsEnabled()}} />
								</Row>
							</Col>
						</Row>
						<Row style={{...cardStyle, marginTop:5 }} type='flex' justify='start'>
							{deviceIsOffline && <SmartStats title={"Warning"} valueStyle={{fontSize: 20, color:'red'}} value={"Disconnected"} /> }
							<SmartStats title={deviceIsOffline ? "Last state" : "State"} valueStyle={{fontSize: 15}} value={StateToString(get(device, "data[0].state"))} />
							<SmartStats title="Battery Voltage" suffix="V" value={get(device, "data[0].pack_V")} />
							<SmartStats title="Battery current" suffix="A" value={get(device, "data[0].pack_A")} />
							<SmartStats title="Output Power" suffix="W" value={get(device, "data[0].output_W")} />
							<SmartStats title="Input Power" suffix="W" value={get(device, "data[0].input_W")} />
							<SmartStats title="Temperature" suffix="°C" value={get(device, "data[0].temperature")} />
							<SmartStats title="State of charge" suffix="%" value={get(device, "data[0].soc_100")} />
							<SmartStats title="Remaining Runtime" suffix="h" value={get(device, "remaining_runtime_s") && (device.remaining_runtime_s/3600).toFixed(1) } />
						</Row>
						<Row type='flex' justify='start'>
							{inputsDetails}
							{outputsDetails}
							{tempDetails}
							{cellsDetails}
						</Row>
						<Row style={{ marginTop: 10}}>
							<Col span={24}>
								<DataChart device_id={props.device_id} datakey="pack_V" axisname="Battery Voltage" />
							</Col>
						</Row>
					</Spin>
				</Modal>
}


export default DeviceDetails;


const cardStyle = { 
	// backgroundColor: '#FDFDFD',
	padding: '10px 15px 10px 15px',
	borderRadius: 10,
	margin: 5,
	boxShadow: '2px 2px #F0F0F0',
	border: 'solid 1px #F0F0F0'
};